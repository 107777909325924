import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";

import "../components/style.scss";

import events from "../images/pricing/events.jpg";
import creative from "../images/pricing/creative headshots.jpg";
import portraits from "../images/pricing/corporate portraits.jpg";

const photography = {
    events: 1,
    corporate: 2,
    creative: 3
}


var selection = photography.events;

function SetSelection(value) {
  selection = value
  return
}

export default () => (
  <Layout>
    <div className="container tabs is-centered">
      <ul>
        <li className={selection === photography.events ? "is-active" : ""}>
          <a href="#" onClick={() => SetSelection(photography.events)}>Event Photography</a>
        </li>
        <li className={selection === photography.corporate ? "is-active" : ""}>
          <a href="#" onClick={() => SetSelection(photography.corporate)}>Corporate Headshots</a>
        </li>
        <li className={selection === photography.creative ? "is-active" : ""}>
          <a href="#" onClick={() => SetSelection(photography.creative)}>Creative Portraits</a>
        </li>
      </ul>
    </div>
    <Pricing />
  </Layout>
)

const Pricing = () => {
  switch (selection) {
    case photography.events:
      return <CorporateEventsPricing />;
      break;
    case photography.corporate:
      return <CorporateHeadshotsPricing />;
      break;
    case photography.creative:
      return <CreativePortraitsPricing />;
      break;
    default:
      return <CorporateEventsPricing />;
  }
}

const CorporateHeadshotsPricing = () => (
  <div className="container">
    <section className="section">
      <div className="columns is-multiline">
        <div className="column is-6">
          <img src={portraits} />
        </div>
        <div className="column is-6 content">
          <p className="title">Individual Headshots</p>
          <p>
            Corporate headshots in photography sessions lasting up to 1 hour at
            your location of choice
          </p>
          <p>
            2 changes of outfit{" "}
            <Link to="/faq">
              (what would be the best outfit for the session?)
            </Link>
          </p>
          <p>
            All images taken during the day will be provided in high resolution
          </p>
          <p>1 hero image with colour correction and post-production</p>
          <p>
            Includes travel and setup costs. Makeup artist available per request
          </p>
          <p>Delivery in 3 working days</p>
          <br />
          <div className="columns level">
            <div className="column is-5">
              <p className="notification is-success has-text-centered">$350</p>
            </div>
            <div className="column is-7 content">
              <p className="is-success is-inverted">
                $40 extra retouched image
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <p className="title">Corporate Headshots</p>
      </div>
      <div className="columns">
        <div className="column is-8 content">
          <p>
            Shot at your location of choice with same set-up for each person.
            Minimum of 4 people. 1 batch per hour, 4 people per batch. 1 photo
            delivered per person with skin and light post-production retouching.
          </p>
        </div>
        <div className="column is-4 has-text-centered">
          <p className="notification is-success">$120 per person</p>
        </div>
      </div>
    </section>
  </div>
);

const CorporateEventsPricing = () => (
  <div className="container">
    <section className="section">
      <div className="columns is-multiline">
        <div className="column is-12 content">
          <h1>I understand how complex preparing an event is.</h1>
          <p>The food, the music,
            the decoration. All has been selected carefully and with attention
            to detail to provide the best experience to your guests and create a
            great impression.
          </p>
        </div>
        <div className="column is-5 content">
          <p>
            <strong>My goal is to capture that outcome with excellent,
            intimate photography</strong>; the atmosphere of the location, the tiny
            details of the day and, most importantly, the people and their
            interactions.
          </p>
          <p>
            I specialise in covering events up to 200
            guests, including corporate drinks & functions, seminars &
            conferences, intimate social events, awards and galas. I do always
            recommend to meet in advance to discuss and plan the day in detail.
          </p>
          <p>
            General event photography starts at $190 per hour with a minimum of
            2 hours. This includes travel time surrounding from Sydney CBD up to
            20 km and shooting, selection and post-production times.
          </p>
        </div>
        <div className="column is-6 is-offset-1">
          <img src={events} />
        </div>
        <div className="column is-12 content">
          <p>
            Once finished, you will receive within 3 working days an unlimited
            selection of the best photos of the day via Dropbox in both high and
            web resolutions ready to share - a faster turnaround and an online
            gallery is also available upon request.
          </p>
        </div>
      </div>
      <div className="section columns is-centered has-text-centered">
        <div className="column" />
        <div className="column is-2 content is-medium">
          <p className="is-size-6">
            <strong>2h event</strong>
          </p>
          <p className="notification is-success">$380</p>
        </div>
        <div className="column" />
        <div className="column is-2 content is-medium">
          <p className="is-size-6">
            <strong>3h event</strong>
          </p>
          <p className="notification is-success">$500</p>
          <p className="is-size-6">
            <small>valued at $570</small>
          </p>
        </div>
        <div className="column" />
        <div className="column is-2 content is-medium">
          <p className="is-size-6">
            <strong>5h event</strong>
          </p>
          <p className="notification is-success">$900</p>
          <p className="is-size-6">
            <small>valued at $950</small>
          </p>
        </div>
        <div className="column" />
      </div>
      <div className="content is-small has-text-centered">
        <p>
          Unlimited photos • Private online gallery available upon request •
          From 6h contact for a quote
        </p>
      </div>
    </section>
  </div>
);

const CreativePortraitsPricing = () => (
  <div className="container">
    <section className="section">
      <div className="columns is-multiline">
        <div className="column is-6">
          <img src={creative} />
        </div>
        <div className="column is-5 is-offset-1 content">
          <p className="title">
            Ideal for websites and other marketing materials.
          </p>
          <p>
            Similar in quality and concept to editorial style photography, these
            customised portraits are a powerful tool to enhance your brand’s
            identity. Let’s play and be creative with concepts and ideas!
          </p>
          <p>
            Set in a working environment or a location reflecting your style,
            unlike corporate headshots these creative portraits might include
            anything from expressive close-ups to medium and full length profile
            images.
          </p>
          <br />
          <p className="notification is-success has-text-centered">from 450</p>
          <br />
          <p>
            <small>
              Creative portraits session quotes include a photography fee for
              either a full-day or half-day rate, plus a fee for the time spent
              on digital downloading, shot selection and post-production.
            </small>
          </p>
        </div>
      </div>
    </section>
  </div>
);
